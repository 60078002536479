import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DashboardServices } from "apis/Services/DashboardService";
import Container from "components/container";
import Text from "components/text";
import {
  Button,
  Calendar,
  Empty,
  Input,
  Modal,
  Select,
  Skeleton,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import LoadingComponent from "components/loading-component";
import ReactECharts from "echarts-for-react";
import ContentContainer from "components/content-container";
import {
  InfoCircleFilled,
  LeftOutlined,
  PieChartFilled,
  RightCircleOutlined,
  RightOutlined,
  SettingFilled,
} from "@ant-design/icons";
import { BarChartOutlined } from "@material-ui/icons";
import DashboardCustomization from "./dashboard-customization";
import { ProjectServices } from "apis/Services/ProjectService";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import weekday from "dayjs/plugin/weekday";
dayjs.extend(weekday);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekStart: 1,
});

export default function Dashboard() {
  const { company_id, id } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState(3);
  const [projectName, setProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isItemsPerDivisionsLoading, setIsItemsPerDivisionsLoading] =
    useState(true);

  const [isItemsPerContractorLoading, setIsItemsPerContractorLoading] =
    useState(true);

  const [
    isContractorDetailAnalysisLoading,
    setIsContractorDetailAnalysisLoading,
  ] = useState(true);

  const [isEntriesStatusAnalysisLoading, setIsEntriesStatusAnalysisLoading] =
    useState(true);

  const [isCriticalPathStatusLoading, setIsCriticalPathStatusLoading] =
    useState(true);

  const [longLeadTimeStatusisLoading, setlongLeadTimeStatusisLoading] =
    useState(true);

  const [isPinpointingDelaysLoading, setIsPinpointingDelaysLoading] =
    useState(true);

  const [isUrgentActionLoading, setIsUrgentActionLoading] = useState(true);
  const [isUserActivityLoading, setIsUserActivityLoading] = useState(true);
  const [deliveriesDueTodayLoading, setDeliveriesDueTodayLoading] =
    useState(true);

  const [contarctorOverviewData, setContarctorOverviewData] = useState();
  const [contarctorOverviewDataTable, setContarctorOverviewDataTable] =
    useState();
  const [
    entriesDistributionAcrossDivisionsData,
    setEntriesDistributionAcrossDivisionsData,
  ] = useState();

  const [
    entriesDistributionAcrossDivisionsDataTable,
    setEntriesDistributionAcrossDivisionsDataTable,
  ] = useState();

  const [contractorsDetailedAnalysis, setContractorsDetailedAnalysis] =
    useState({});

  const [
    entriesStatusAnalysisVisualReportData,
    setEntriesStatusAnalysisVisualReportData,
  ] = useState({});

  const [criticalPathItemsReportData, setCriticalPathItemsReportData] =
    useState({});

  const [
    pinpointingProcurementDelaysVisualReportData,
    setPinpointingProcurementDelaysVisualReport,
  ] = useState({});

  const [urgentActionReportData, setUrgentActionReportData] = useState({});
  const [deliveriesDueTodayReportData, setDeliveriesDueTodayReportData] =
    useState({});
  const [todaysDate, setTodaysDate] = useState();

  const [
    usersActivityAnalysisVisualReportData,
    setUsersActivityAnalysisVisualReportData,
  ] = useState({});

  const [selectedContractor, setSelectedContractor] = useState(null);

  const [longLeadTimeStatusReportData, setLongLeadTimeStatusReportData] =
    useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedUrgentAction, setSelectedUrgentAction] = useState(null);
  const [selectedLongLeadTimeCategory, setSelectedLongLeadTimeCategory] =
    useState(null);

  const [isContractorChartModalOpen, setIsContractorChartModalOpen] =
    useState(false);

  const [disabledBallInCourt, setDisabledBallInCourt] = useState(true);
  const [disabledItemsPending, setDisabledItemsPending] = useState(true);

  const [isShowCustomizeDashboard, setIsShowCustomizeDashboard] =
    useState(false);

  const [dashboardCusomizationData, setDashboardCusomizationData] = useState(
    {}
  );

  const [isCalendarView, setIsCalendarView] = useState(false);

  // show which chart details in popup when clicked
  const [activeChartType, setActiveChartType] = useState(null); // 'contractor' or 'division'
  const [leadTimeValue, setLeadTimeValue] = useState(null); // 'contractor' or 'division'
  const [showGetFromSubmittals, setShowGetFromSubmittals] = useState(false);

  const [disableLeadTimeSaveButton, setDisableLeadTimeSaveButton] =
    useState(true);

  let code = localStorage.getItem("token");
  let email = localStorage.getItem("email");
  let ReqObj = {
    company: company_id,
    project: id,
    code,
    loginEmail: email,
  };

  function getDashboardCustomization() {
    DashboardServices.getDashboardCustomization(ReqObj)
      .then((res) => {
        setLeadTimeValue(parseInt(res?.content?.longLeadTime));
        setDashboardCusomizationData(res?.content?.dashboardReportsInfo);
        setisLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally();
  }

  useEffect(() => {
    setisLoading(true);
    getDashboardCustomization();
    loadAllReports();
  }, []);

  function loadAllReports() {
    DashboardServices.getReportAccessLevel(ReqObj)
      .then((res) => {
        setAccessLevel(parseInt(res?.accessLevel));
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        contractorsOverviewVisualReport();
        contractorsDetailedAnalysisVisualReport();
        entriesStatusAnalysisVisualReport();
        criticalPathItemsReport();
        entriesBottleNeckAnalysisVisualReport();
        usersActivityAnalysisVisualReport();
        entriesDistributionAcrossDivisionsReport();
        urgentActionReport();
        deliveriesDueTodayReport();
        longLeadTimeStatusReport();
        setisLoading(false);
      });
  }

  function contractorsOverviewVisualReport() {
    DashboardServices.contractorsOverviewVisualReport(ReqObj)
      .then((res) => {
        setContarctorOverviewData(res?.content);
        setProjectName(res?.projectName);
        setProjectAddress(res?.projectAddress);
        setIsItemsPerContractorLoading(false);
        setContarctorOverviewDataTable(res?.table);
      })
      .catch((err) => {
        // console.log("Full error object:", err?.content);
        // console.log("Error response:", err?.response);
        // console.log("Error status:", err?.response?.status);

        if (err?.content === "No procurement log found") {
          setShowGetFromSubmittals(true);
        }
      })
      .finally();
  }

  function contractorsDetailedAnalysisVisualReport() {
    DashboardServices.contractorsDetailedAnalysisVisualReport(ReqObj)
      .then((res) => {
        setContractorsDetailedAnalysis(res?.content);
        setIsContractorDetailAnalysisLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err?.content === "No procurement log found") {
          setShowGetFromSubmittals(true);
        }
      })
      .finally();
  }

  function entriesStatusAnalysisVisualReport() {
    DashboardServices.entriesStatusAnalysisVisualReport(ReqObj)
      .then((res) => {
        setEntriesStatusAnalysisVisualReportData(res?.content);
        setIsEntriesStatusAnalysisLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err?.content === "No procurement log found") {
          setShowGetFromSubmittals(true);
        }
      })
      .finally();
  }

  function criticalPathItemsReport() {
    DashboardServices.criticalPathItemsReport(ReqObj)
      .then((res) => {
        setCriticalPathItemsReportData(res?.content);
        setIsCriticalPathStatusLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err?.content === "No procurement log found") {
          setShowGetFromSubmittals(true);
        }
      })
      .finally();
  }

  function entriesBottleNeckAnalysisVisualReport() {
    DashboardServices.entriesBottleNeckAnalysisVisualReport(ReqObj)
      .then((res) => {
        setPinpointingProcurementDelaysVisualReport(res?.content);
        setIsPinpointingDelaysLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err?.content === "No procurement log found") {
          setShowGetFromSubmittals(true);
        }
      })
      .finally();
  }

  function usersActivityAnalysisVisualReport() {
    DashboardServices.usersActivityAnalysisVisualReport(ReqObj)
      .then((res) => {
        setUsersActivityAnalysisVisualReportData(res?.content);
        if (
          res?.content?.entriesAssignedInfo &&
          Object.keys(res?.content?.entriesAssignedInfo).length > 0
        ) {
          setDisabledBallInCourt(false);
        }
        if (
          res?.content?.pendingApprovalsInfo &&
          res?.content?.pendingApprovalsInfo?.length > 0
        ) {
          setDisabledItemsPending(false);
        }

        setIsUserActivityLoading(false);
        setFirstName(res?.userInfo?.firstName);
        setLastName(res?.userInfo?.lastName);
      })
      .catch((err) => {
        // console.log(err);
        if (err?.content === "No procurement log found") {
          setShowGetFromSubmittals(true);
        }
      })
      .finally();
  }

  function entriesDistributionAcrossDivisionsReport() {
    DashboardServices.entriesDistributionAcrossDivisionsReport(ReqObj)
      .then((res) => {
        // console.log(res?.content);
        setEntriesDistributionAcrossDivisionsData(res?.content);
        setEntriesDistributionAcrossDivisionsDataTable(res?.table);
        setIsItemsPerDivisionsLoading(false);
        // setUsersActivityAnalysisVisualReportData(res?.content);
      })
      .catch((err) => {
        // console.log(err);
        if (err?.content === "No procurement log found") {
          setShowGetFromSubmittals(true);
        }
      })
      .finally();
  }

  function urgentActionReport() {
    DashboardServices.urgentActionReport(ReqObj)
      .then((res) => {
        // console.log(res?.content);
        setUrgentActionReportData(res?.content);
        setIsUrgentActionLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err?.content === "No procurement log found") {
          setShowGetFromSubmittals(true);
        }
      })
      .finally();
  }

  const [validTodaysDeliveries, setValidTodaysDeliveries] = useState(null);
  function deliveriesDueTodayReport() {
    DashboardServices.deliveriesDueTodayReport(ReqObj)
      .then((res) => {
        // console.log(res, "!!!");
        const content = res?.content || [];
        const todaysDate = res?.todaysDate;

        setDeliveriesDueTodayReportData(content);
        setTodaysDate(todaysDate);

        // Find today's deliveries
        const todaysDeliveries = content.find(([date]) => date === todaysDate);

        const validTodaysDeliveries = todaysDeliveries
          ? todaysDeliveries[1].filter((delivery) => delivery !== "")
          : [];

        // Set in state if needed
        setValidTodaysDeliveries(validTodaysDeliveries);

        setDeliveriesDueTodayLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setDeliveriesDueTodayLoading(false);
        if (err?.content === "No procurement log found") {
          setShowGetFromSubmittals(true);
        }
      });
  }

  function longLeadTimeStatusReport() {
    DashboardServices.longLeadTimeStatusReport(ReqObj, leadTimeValue || null)
      .then((res) => {
        setLongLeadTimeStatusReportData(res?.content);
        setlongLeadTimeStatusisLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err?.content === "No procurement log found") {
          setShowGetFromSubmittals(true);
        }
      })
      .finally();
  }

  const entriesDistributionAcrossDivisionsPieData =
    entriesDistributionAcrossDivisionsData
      ? Object.entries(entriesDistributionAcrossDivisionsData).map(
          ([key, value]) => ({
            name: `${key} (${value.cnt})`,
            value: value.percent,
          })
        )
      : [];

  function ApplyDashboardCustomizationChanges() {
    setisLoading();
    getDashboardCustomization();
  }

  const entriesDistributionAcrossDivisionsPieChart = {
    tooltip: {
      trigger: "item",
      formatter: "{b}: {c}%",
    },
    series: [
      {
        name: "Contractor",
        type: "pie",
        radius: "80%",
        data: entriesDistributionAcrossDivisionsPieData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  const contarctorOverviewPieData = contarctorOverviewData
    ? Object.entries(contarctorOverviewData).map(([key, value]) => ({
        name: `${key} (${value.cnt})`,
        value: value.percent,
      }))
    : [];

  const contarctorOverviewPieChart = {
    // title: {
    //   text: "Contractors Overview",
    //   left: "center",
    // },
    tooltip: {
      trigger: "item",
      formatter: "{b}: {c}%",
    },
    series: [
      {
        name: "Contractor",
        type: "pie",
        radius: "80%",
        data: contarctorOverviewPieData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const contractorOptions = contractorsDetailedAnalysis
    ? Object.keys(contractorsDetailedAnalysis).map((contractor) => ({
        label: contractor,
        value: contractor,
      }))
    : [];

  const contractorsDetailedAnalysisData = selectedContractor
    ? [
        {
          name: `Early (${contractorsDetailedAnalysis[selectedContractor]?.earlyCnt})`,
          value:
            contractorsDetailedAnalysis[selectedContractor]?.earlyCntPercent,
          itemStyle: { color: "#28a745" },
        },
        {
          name: `On Time (${contractorsDetailedAnalysis[selectedContractor]?.onTimeCnt})`,
          value:
            contractorsDetailedAnalysis[selectedContractor]?.onTimeCntPercent,
          itemStyle: { color: "#007bff" },
        },
        {
          name: `Late (${contractorsDetailedAnalysis[selectedContractor]?.lateCnt})`,
          value:
            contractorsDetailedAnalysis[selectedContractor]?.lateCntPercent,
          itemStyle: { color: "#dc3545" },
        },
        {
          name: `Pending (${contractorsDetailedAnalysis[selectedContractor]?.pendingCnt})`,
          value:
            contractorsDetailedAnalysis[selectedContractor]?.pendingCntPercent,
          itemStyle: { color: "#fd7e14" },
        },
        {
          name: `Missing (${contractorsDetailedAnalysis[selectedContractor]?.missingCnt})`,
          value:
            contractorsDetailedAnalysis[selectedContractor]?.missingCntPercent,
          itemStyle: { color: "#D3D3D3" },
          // itemStyle: { color: "#353550" },
        },
      ].filter((item) => item.value > 0)
    : [];

  const contractorsDetailedAnalysisPieChart = {
    tooltip: {
      trigger: "item",
      formatter: "{b}: {c}%",
    },
    legend: {
      orient: "vertical",
      right: 10,
      bottom: 10,
    },

    series: [
      {
        name: "No Data",
        type: "pie",
        radius: "80%",
        data: contractorsDetailedAnalysisData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const entriesStatusAnalysisVisualReportBarChart = {
    // title: {
    //   text: "Entry Status Analysis",
    //   left: "center",
    // },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params) {
        const counts = {
          Draft: entriesStatusAnalysisVisualReportData?.draftEntriesCnt || 0,
          "Approaching Deadline":
            entriesStatusAnalysisVisualReportData?.approachingDeadlineEntriesCnt ||
            0,
          Overdue:
            entriesStatusAnalysisVisualReportData?.overdueEntriesCnt || 0,
          Completed:
            entriesStatusAnalysisVisualReportData?.completedEntriesCnt || 0,
        };

        const item = params[0];
        return `${item.name}: ${counts[item.name]} submittals`;
      },
    },
    grid: {
      left: "2%",
      // right: 50,
      bottom: "10%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      max: 100,
      axisLabel: {
        formatter: "{value}%",
      },
    },
    yAxis: {
      type: "category",
      data: ["Draft", "Approaching Deadline", "Overdue", "Completed"],
    },
    series: [
      {
        name: "Percentage",
        type: "bar",
        data: [
          entriesStatusAnalysisVisualReportData?.draftEntriesPercent || 0,
          entriesStatusAnalysisVisualReportData?.approachingDeadlineEntriesPercent ||
            0,
          entriesStatusAnalysisVisualReportData?.overdueEntriesPercent || 0,
          entriesStatusAnalysisVisualReportData?.completedEntriesPercent || 0,
        ],
        itemStyle: {
          color: (params) => {
            const colors = {
              Draft: "#D3D3D3",
              "Approaching Deadline": "#f39c12",
              Overdue: "#dc3545",
              Completed: "#007bff",
            };
            return colors[params.name] || "#999";
          },
          barBorderRadius: [0, 5, 5, 0],
        },
        label: {
          show: true,
          position: "right",
          formatter: "{c}%",
        },
      },
    ],
  };

  const criticalPathItemsReportBarChart = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params) {
        const counts = {
          Healthy: criticalPathItemsReportData?.healthy?.cnt || 0,
          "Not Started": criticalPathItemsReportData?.notStarted?.cnt || 0,
          "Approaching Deadline":
            criticalPathItemsReportData?.approaching?.cnt || 0,
          "Delayed Workflow":
            criticalPathItemsReportData?.delayedWorkflow?.cnt || 0,
        };
        const item = params[0];
        return `${item.name}: ${counts[item.name]} submittals`;
      },
    },
    grid: {
      left: "2%",
      bottom: "10%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}",
      },
      splitNumber: 5,
      minInterval: 1,
    },
    yAxis: {
      type: "category",
      data: [
        "Healthy",
        "Not Started",
        "Approaching Deadline",
        "Delayed Workflow",
      ],
    },
    series: [
      {
        name: "Count",
        type: "bar",
        data: [
          criticalPathItemsReportData?.healthy?.cnt || 0,
          criticalPathItemsReportData?.notStarted?.cnt || 0,
          criticalPathItemsReportData?.approaching?.cnt || 0,
          criticalPathItemsReportData?.delayedWorkflow?.cnt || 0,
        ],
        itemStyle: {
          color: (params) => {
            const colors = {
              Healthy: "#007bff",
              "Not Started": "#D3D3D3",
              "Approaching Deadline": "#f39c12",
              "Delayed Workflow": "#dc3545",
            };
            return colors[params.name] || "#999";
          },
          barBorderRadius: [0, 5, 5, 0],
        },
        label: {
          show: true,
          position: "right",
        },
      },
    ],
  };

  const longLeadTimeStatusReportDataBarChart = {
    // title: {
    //   text: "Long Lead Time Status Report",
    //   left: "center",
    // },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params) {
        const item = params[0];
        return `${item.name}: ${item.value} submittals`;
      },
    },
    grid: {
      left: "2%",
      bottom: "10%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}", // Removed percentage symbol
      },
    },
    yAxis: {
      type: "category",
      data: ["Not Started", "Delayed Workflow"],
    },
    series: [
      {
        name: "Submittals Count",
        type: "bar",
        data: [
          longLeadTimeStatusReportData?.notStarted?.cnt || 0,
          longLeadTimeStatusReportData?.delayedWorkflow?.cnt || 0,
        ],
        itemStyle: {
          color: (params) => {
            const colors = {
              "Not Started": "#D3D3D3",
              "Delayed Workflow": "#dc3545",
            };
            return colors[params.name] || "#999";
          },
          barBorderRadius: [0, 5, 5, 0],
        },
        label: {
          show: true,
          position: "right",
          formatter: "{c}", // Display count instead of percentage
        },
      },
    ],
  };

  const pinpointingProcurementDelaysVisualReportDataBarChart = {
    // title: {
    //   text: "Pinpointing Procurement Delays",
    //   left: "center",
    // },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        const data = Object.values(
          pinpointingProcurementDelaysVisualReportData
        )[params.dataIndex];
        return `${params.name}<br/>Late: ${data.late} (${params.value}%)`;
      },
    },
    grid: {
      left: "2%",
      right: "10%",
      bottom: "10%",
      top: "15%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      max: 100,
      axisLabel: {
        formatter: "{value}%",
      },
    },
    yAxis: {
      type: "category",
      data: [
        `Contract Issue Release\n(Avg ${
          Object.values(pinpointingProcurementDelaysVisualReportData)[0]
            ?.daysDiffAvg || 0
        } days)`,
        `Submittal Issued\n(Avg ${
          Object.values(pinpointingProcurementDelaysVisualReportData)[1]
            ?.daysDiffAvg || 0
        } days)`,
        `Submittal Returned\n(Avg ${
          Object.values(pinpointingProcurementDelaysVisualReportData)[2]
            ?.daysDiffAvg || 0
        } days)`,
      ],
      axisLabel: {
        interval: 0,
        formatter: function (value) {
          return value.split("\n").join("\n");
        },
      },
    },
    series: [
      {
        name: "Late",
        type: "bar",
        data: Object.values(pinpointingProcurementDelaysVisualReportData).map(
          (item) => item.latePerc
        ),
        itemStyle: { color: "#dc3545", barBorderRadius: [0, 5, 5, 0] },
        label: {
          show: true,
          position: "right",
          formatter: "{c}%",
        },
      },
    ],
  };

  const urgentActionReportChart = {
    // title: {
    //   text: "Urgent Action Report",
    //   left: "center",
    // },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        let category = params.name.includes("Approaching")
          ? "approaching"
          : "delayed";
        return `${params.name}<br/>Click to view submittals`;
      },
    },
    grid: {
      left: "2%",
      right: "10%",
      bottom: "10%",
      top: "15%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}",
      },
    },
    yAxis: {
      type: "category",
      data: [
        `Approaching Due Date\n(${
          urgentActionReportData.approaching?.cnt || 0
        } items)`,
        `Delayed\n(${urgentActionReportData.delayed?.cnt || 0} items)`,
      ],
      axisLabel: {
        interval: 0,
        formatter: function (value) {
          return value.split("\n").join("\n");
        },
      },
    },
    series: [
      {
        name: "Count",
        type: "bar",
        data: [
          {
            value: urgentActionReportData.approaching?.cnt || 0,
            itemStyle: { color: "#f39c12", borderRadius: [0, 5, 5, 0] },
          },
          {
            value: urgentActionReportData.delayed?.cnt || 0,
            itemStyle: { color: "#dc3545", borderRadius: [0, 5, 5, 0] },
          },
        ],
        label: {
          show: true,
          position: "right",
          formatter: "{c}",
        },
      },
    ],
  };

  const handleItemsPerDivisionChartClick = React.useCallback((params) => {
    setActiveChartType("Division");
    setIsContractorChartModalOpen(true);
  }, []);

  const handleItemsPerContractorChartClick = React.useCallback((params) => {
    setActiveChartType("Contractor");
    setIsContractorChartModalOpen(true);
  }, []);

  const handleLongLeadTimeChartClick = React.useCallback((params) => {
    if (params.name === "Delayed Workflow") {
      setSelectedLongLeadTimeCategory("delayedWorkflow");
    } else {
      setSelectedLongLeadTimeCategory("notStarted");
    }
    setModalType("longLeadTime");
    setIsModalOpen(true);
  }, []);

  const handleChartClick = React.useCallback((params) => {
    const isApproaching = params.name.includes("Approaching");
    setSelectedUrgentAction(isApproaching ? "approaching" : "delayed");
    setModalType("urgentAction");
    setIsModalOpen(true);
  }, []);

  const gaugeChart = {
    title: {
      text: "Contributions Percentage",
      left: "center",
    },
    series: [
      {
        name: "Progress",
        type: "gauge",
        center: ["50%", "60%"],
        radius: "90%",
        detail: {
          formatter: (value) => `${value.toFixed(0)}%`,
        },
        data: [
          {
            value:
              usersActivityAnalysisVisualReportData?.contributionsPercentageInProject ||
              0,
          },
        ],
        axisLine: {
          lineStyle: {
            width: 10,
          },
        },
        pointer: {
          width: 4,
        },
        title: {
          fontSize: 14,
        },
      },
    ],
  };

  useEffect(() => {
    if (contractorOptions.length > 0 && !selectedContractor) {
      setSelectedContractor(contractorOptions[0].value);
    }
  }, [contractorOptions]);

  const handleOk = () => {
    setIsModalOpen(false);
    setModalType("");
  };

  const handleContractorChartModalClose = () => {
    setIsContractorChartModalOpen(false);
  };

  const chartRef = useRef(null);

  useEffect(() => {
    if (isContractorChartModalOpen && chartRef.current) {
      setTimeout(() => {
        chartRef.current.getEchartsInstance().resize();
      }, 100);
    }
  }, [isContractorChartModalOpen]);

  const ContractorsTableColumns = [
    {
      title: "Contractor Name",
      dataIndex: "contractorName",
      key: "contractorName",
      sorter: (a, b) => a.contractorName.localeCompare(b.contractorName),
    },
    {
      title: "Count",
      dataIndex: "contractorCnt",
      key: "contractorCnt",
      sorter: (a, b) => a.contractorCnt - b.contractorCnt,
    },
    {
      title: "Percent",
      dataIndex: "contractorPercent",
      key: "contractorPercent",
      sorter: (a, b) => a.contractorPercent - b.contractorPercent,
      render: (percent) => `${percent}%`,
    },
  ];

  const DivisionsTableColumns = [
    {
      title: "Division Name",
      dataIndex: "divName",
      key: "divName",
      sorter: (a, b) => a.divName.localeCompare(b.divName),
    },
    {
      title: "Count",
      dataIndex: "divCnt",
      key: "divCnt",
      sorter: (a, b) => a.divCnt - b.divCnt,
    },
    {
      title: "Percent",
      dataIndex: "divPercent",
      key: "divPercent",
      sorter: (a, b) => a.divPercent - b.divPercent,
      render: (percent) => `${percent}%`,
    },
  ];

  const itemsPerContractorTabs = [
    {
      key: "1",
      label: "Chart",
      children: (
        <ReactECharts
          ref={chartRef}
          option={contarctorOverviewPieChart}
          key={contarctorOverviewPieData.length}
          style={{
            width: "100%",
            height: "80vh",
          }}
        />
      ),
    },
    {
      key: "2",
      label: "List",
      children: (
        <Table
          dataSource={contarctorOverviewDataTable}
          columns={ContractorsTableColumns}
          pagination={{ pageSize: 10 }}
          size="large"
          style={{ height: "70vh", overflowY: "auto" }}
        />
      ),
    },
  ];

  const itemsPerDivisionTabs = [
    {
      key: "1",
      label: "Chart",
      children: (
        <ReactECharts
          ref={chartRef}
          option={entriesDistributionAcrossDivisionsPieChart}
          key={contarctorOverviewPieData.length}
          style={{
            width: "100%",
            height: "80vh",
          }}
        />
      ),
    },
    {
      key: "2",
      label: "List",
      children: (
        <Table
          dataSource={entriesDistributionAcrossDivisionsDataTable}
          columns={DivisionsTableColumns}
          pagination={{ pageSize: 10 }}
          size="large"
          style={{ height: "70vh", overflowY: "auto" }}
        />
      ),
    },
  ];

  const [visibleComponents, setVisibleComponents] = useState([]);

  useEffect(() => {
    const componentConfigs = Object.entries(dashboardCusomizationData)
      .filter(([_, config]) => config.visibility === "1")
      .map(([key, config]) => ({
        key,
        index: config.index,
        label: config.label,
      }))
      .sort((a, b) => a.index - b.index);

    setVisibleComponents(componentConfigs);
  }, [dashboardCusomizationData]);

  const GetFromSubmittals = () => {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = { company: company_id, project: id, code, loginEmail: email };
    ProjectServices.getProjectsDetailsFromSubmittals(ReqObj)
      .then((res) => {
        // dispatch(saveProjectDetails(res));
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setisLoading(false);
          loadAllReports();
        }, 200);
      });
  };

  const componentMap = {
    entriesDistributionAcrossDivisionsReport: (
      <div className="pt-2 border-1 border rounded-4" style={{ width: "48%" }}>
        <div className="d-flex justify-content-center align-items-center px-3 position-relative">
          <h6 className="fw-bold text-center p-0 m-0">
            {
              dashboardCusomizationData
                ?.entriesDistributionAcrossDivisionsReport?.label
            }
          </h6>

          <Tooltip title="Distribution of items across divisions in the procurement log">
            <InfoCircleFilled className="position-absolute end-0 me-4 opacity-50" />
          </Tooltip>
        </div>
        <div className="w-100 bg-body d-flex justify-content-center align-items-center">
          {isItemsPerDivisionsLoading ? (
            <Skeleton.Node
              active={isItemsPerDivisionsLoading}
              className="my-3"
              style={{ width: 220, height: 220 }}
            >
              <PieChartFilled style={{ fontSize: 80, color: "#bfbfbf" }} />
            </Skeleton.Node>
          ) : (
            <ReactECharts
              option={entriesDistributionAcrossDivisionsPieChart}
              key={contarctorOverviewPieData.length}
              style={{
                width: "100%",
                height: 350,
              }}
              onEvents={{
                click: handleItemsPerDivisionChartClick,
              }}
            />
          )}
        </div>
      </div>
    ),

    contractorsOverviewVisualReport: (
      <div className="pt-2 border-1 border rounded-4" style={{ width: "48%" }}>
        <div className="d-flex justify-content-center align-items-center px-3 position-relative">
          <h6 className="fw-bold text-center p-0 m-0">
            {dashboardCusomizationData?.contractorsOverviewVisualReport?.label}
          </h6>

          <Tooltip
            title="Distribution of items across contractors in the procurement log
"
          >
            <InfoCircleFilled className="position-absolute end-0 me-4 opacity-50" />
          </Tooltip>
        </div>
        <div className="w-100 bg-body d-flex justify-content-center align-items-center">
          {isItemsPerContractorLoading ? (
            <Skeleton.Node
              active={isItemsPerContractorLoading}
              className="my-3"
              style={{ width: 220, height: 220 }}
            >
              <PieChartFilled style={{ fontSize: 80, color: "#bfbfbf" }} />
            </Skeleton.Node>
          ) : (
            <ReactECharts
              option={contarctorOverviewPieChart}
              key={contarctorOverviewPieData.length}
              style={{
                width: "100%",
                height: 350,
              }}
              onEvents={{
                click: handleItemsPerContractorChartClick,
              }}
            />
          )}
        </div>
      </div>
    ),
    contractorsDetailedAnalysisVisualReport: (
      <div className="pt-2 border-1 border rounded-4" style={{ width: "48%" }}>
        <div className="d-flex flex-column gap-1 justify-content-center align-items-center px-3"></div>
        {isContractorDetailAnalysisLoading ? (
          <div className="d-flex justify-content-center align-items-center my-3">
            <Skeleton.Node
              active={isContractorDetailAnalysisLoading}
              className="my-3"
              style={{ width: 220, height: 220 }}
            >
              <PieChartFilled style={{ fontSize: 80, color: "#bfbfbf" }} />
            </Skeleton.Node>
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-center px-3 position-relative">
              <div className=" d-flex flex-column gap-1">
                <h6 className="fw-bold text-center p-0 m-0">
                  {
                    dashboardCusomizationData
                      ?.contractorsDetailedAnalysisVisualReport?.label
                  }
                </h6>
                <Select
                  style={{ width: 250 }}
                  options={contractorOptions}
                  disabled={!selectedContractor || isLoading}
                  value={selectedContractor}
                  onChange={setSelectedContractor}
                />
              </div>

              <Tooltip
                title="Status of items assigned to specified contractor
"
              >
                <InfoCircleFilled className="position-absolute end-0 me-4 opacity-50" />
              </Tooltip>
            </div>

            <ReactECharts
              option={contractorsDetailedAnalysisPieChart}
              key={contractorsDetailedAnalysisData.length}
              style={{
                width: "100%",
                height: 350,
              }}
            />
          </div>
        )}
      </div>
    ),

    entriesStatusAnalysisVisualReport: (
      <div className="pt-2 border-1 border rounded-4" style={{ width: "48%" }}>
        {isEntriesStatusAnalysisLoading ? (
          <div className="d-flex justify-content-center align-items-center my-3">
            <Skeleton.Node
              active={isEntriesStatusAnalysisLoading}
              className="my-3 mt-3"
              style={{ width: 460, height: 260 }}
            >
              <BarChartOutlined style={{ fontSize: 80, color: "#bfbfbf" }} />
            </Skeleton.Node>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center px-3 position-relative">
              <h6 className="fw-bold text-center p-0 m-0">
                {
                  dashboardCusomizationData?.entriesStatusAnalysisVisualReport
                    ?.label
                }
              </h6>

              <Tooltip title="Show status of all items in procurement log">
                <InfoCircleFilled className="position-absolute end-0 me-4 opacity-50" />
              </Tooltip>
            </div>

            <ReactECharts
              option={entriesStatusAnalysisVisualReportBarChart}
              key={contarctorOverviewPieData.length}
              style={{
                width: "100%",
                height: 350,
              }}
            />
          </>
        )}
      </div>
    ),

    criticalPathStatusReport: (
      <div className="pt-2 border-1 border rounded-4" style={{ width: "48%" }}>
        {isCriticalPathStatusLoading ? (
          <div className="d-flex justify-content-center align-items-center my-3">
            <Skeleton.Node
              active={isCriticalPathStatusLoading}
              className="my-3 mt-3"
              style={{ width: 460, height: 260 }}
            >
              <BarChartOutlined style={{ fontSize: 80, color: "#bfbfbf" }} />
            </Skeleton.Node>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center px-3 position-relative">
              <h6 className="fw-bold text-center p-0 m-0">
                {dashboardCusomizationData?.criticalPathStatusReport?.label}
              </h6>

              <Tooltip title="Shows items that are flagged as critical path and their current status">
                <InfoCircleFilled className="position-absolute end-0 me-4 opacity-50" />
              </Tooltip>
            </div>

            <ReactECharts
              option={criticalPathItemsReportBarChart}
              key={criticalPathItemsReportData.length}
              style={{
                width: "100%",
                height: 350,
              }}
            />
          </>
        )}
      </div>
    ),

    longLeadTimeStatusReport: (
      <div className="pt-2 border-1 border rounded-4" style={{ width: "48%" }}>
        {longLeadTimeStatusisLoading ? (
          <div className="d-flex justify-content-center align-items-center my-3">
            <Skeleton.Node
              active={longLeadTimeStatusisLoading}
              className="my-3 mt-3"
              style={{ width: 460, height: 260 }}
            >
              <BarChartOutlined style={{ fontSize: 80, color: "#bfbfbf" }} />
            </Skeleton.Node>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center px-3 position-relative">
              <h6 className="fw-bold text-center p-0 m-0">
                {dashboardCusomizationData?.longLeadTimeStatusReport?.label}
              </h6>

              <Tooltip title="Show status of items with lead time value ≥ specified lead time">
                <InfoCircleFilled className="position-absolute end-0 me-4 opacity-50" />
              </Tooltip>
            </div>

            <ReactECharts
              option={longLeadTimeStatusReportDataBarChart}
              style={{ width: "100%", height: 350 }}
              onEvents={{
                click: handleLongLeadTimeChartClick,
              }}
            />
            <div className="d-flex gap-2 align-items-cente p-2 justify-content-end">
              <label
                htmlFor="leadTimeValue"
                style={{ fontSize: "0.9rem" }}
                className="fw-bold opacity-75"
              >
                Entries with Lead Time ≥
              </label>
              <Input
                id="leadTimeValue"
                style={{ width: "50px", textAlign: "center" }}
                type="number"
                size="small"
                min="0"
                step="1"
                onChange={(e) => {
                  setLeadTimeValue(e.target.value);
                  setDisableLeadTimeSaveButton(false);
                }}
                defaultValue={leadTimeValue}
                onKeyDown={(e) => {
                  if (e.key === "." || e.key === "e") e.preventDefault();
                }}
              />

              <label
                htmlFor="leadTimeValue"
                style={{ fontSize: "0.9rem" }}
                className=" fw-bold opacity-75"
              >
                weeks
              </label>
              <Button
                type="primary"
                size="small"
                disabled={!leadTimeValue || disableLeadTimeSaveButton}
                onClick={() => {
                  longLeadTimeStatusReport();
                  setDisableLeadTimeSaveButton(true);
                }}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </div>
    ),

    pinpointingProcurementDelaysVisualReport: (
      <div className="pt-2 border-1 border rounded-4" style={{ width: "48%" }}>
        {isPinpointingDelaysLoading ? (
          <div className="d-flex justify-content-center align-items-center my-3">
            <Skeleton.Node
              active={isPinpointingDelaysLoading}
              className="my-3 mt-3"
              style={{ width: 460, height: 260 }}
            >
              <BarChartOutlined style={{ fontSize: 80, color: "#bfbfbf" }} />
            </Skeleton.Node>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center px-3 position-relative">
              <h6 className="fw-bold text-center p-0 m-0">
                {
                  dashboardCusomizationData
                    ?.pinpointingProcurementDelaysVisualReport?.label
                }
              </h6>

              <Tooltip title="Causes of work flow delays across delayed items in the procurement log">
                <InfoCircleFilled className="position-absolute end-0 me-4 opacity-50" />
              </Tooltip>
            </div>

            <ReactECharts
              option={pinpointingProcurementDelaysVisualReportDataBarChart}
              key={pinpointingProcurementDelaysVisualReportData.length}
              style={{
                width: "100%",
                height: 350,
              }}
            />
          </>
        )}
      </div>
    ),

    urgentActionReport: (
      <div className="pt-2 border-1 border rounded-4" style={{ width: "48%" }}>
        {isUrgentActionLoading ? (
          <div className="d-flex justify-content-center align-items-center my-3">
            <Skeleton.Node
              active={isUrgentActionLoading}
              className="my-3 mt-3"
              style={{ width: 460, height: 260 }}
            >
              <BarChartOutlined style={{ fontSize: 80, color: "#bfbfbf" }} />
            </Skeleton.Node>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center px-3 position-relative">
              <h6 className="fw-bold text-center p-0 m-0">
                {dashboardCusomizationData?.urgentActionReport?.label}
              </h6>

              <Tooltip title="Shows number of items in procurement log that are either delayed, or approaching due date">
                <InfoCircleFilled className="position-absolute end-0 me-4 opacity-50" />
              </Tooltip>
            </div>

            <ReactECharts
              option={urgentActionReportChart}
              key={urgentActionReportData.length}
              style={{
                width: "100%",
                height: 350,
              }}
              onEvents={{
                click: handleChartClick,
              }}
            />
          </>
        )}
      </div>
    ),
    deliveriesDueTodayReport: (
      <div className="pt-2 border-1 border rounded-4" style={{ width: "48%" }}>
        {deliveriesDueTodayLoading ? (
          <div className="d-flex justify-content-center align-items-center my-3">
            <Skeleton.Node
              active={deliveriesDueTodayLoading}
              className="my-3 mt-3"
              style={{ width: 220, height: 220 }}
            >
              <BarChartOutlined style={{ fontSize: 80, color: "#bfbfbf" }} />
            </Skeleton.Node>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center px-3 position-relative">
              <h6 className="fw-bold text-center p-0 m-0">
                {dashboardCusomizationData?.deliveriesDueTodayReport?.label}
              </h6>

              <Tooltip title="Show items being delivered today (future/past dates)">
                <InfoCircleFilled className="position-absolute end-0 me-4 opacity-50" />
              </Tooltip>
            </div>

            {isCalendarView ? (
              <div
                style={{ height: "340px", overflow: "auto" }}
                className=" p-1"
              >
                <Calendar
                  fullscreen
                  mode="month"
                  headerRender={({ value, type, onChange, onTypeChange }) => {
                    const current = value.clone();
                    const localeData = value.localeData();
                    const monthOptions = [];

                    // Generate all month options
                    for (let i = 0; i < 12; i++) {
                      const monthClone = current.clone().month(i);
                      monthOptions.push(
                        <Select.Option key={i} value={i}>
                          {localeData.monthsShort(monthClone)}
                        </Select.Option>
                      );
                    }

                    const year = value.year();
                    const month = value.month();

                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          padding: "8px 16px",
                        }}
                      >
                        <div className="d-flex">
                          <Button
                            onClick={() => {
                              const newValue = value
                                .clone()
                                .subtract(1, "month");
                              onChange(newValue);
                            }}
                            style={{ marginRight: "8px" }}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <LeftOutlined />
                          </Button>
                          <Select
                            value={month}
                            onChange={(newMonth) => {
                              const newValue = value.clone().month(newMonth);
                              onChange(newValue);
                            }}
                            style={{ width: "100px" }}
                          >
                            {monthOptions}
                          </Select>
                          <Select
                            value={year}
                            onChange={(newYear) => {
                              const newValue = value.clone().year(newYear);
                              onChange(newValue);
                            }}
                            style={{ marginLeft: "8px", width: "80px" }}
                          >
                            {Array.from(
                              { length: 10 },
                              (_, i) => year - 5 + i
                            ).map((item) => (
                              <Select.Option key={item} value={item}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                          <Button
                            onClick={() => {
                              const newValue = value.clone().add(1, "month");
                              onChange(newValue);
                            }}
                            style={{ marginLeft: "8px" }}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <RightOutlined />
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                  dateCellRender={(date) => {
                    const formattedDate = date.format("MM-DD-YYYY");
                    const deliveryEntry = deliveriesDueTodayReportData.find(
                      ([deliveryDate]) => deliveryDate === formattedDate
                    );

                    if (deliveryEntry) {
                      const [, deliveries] = deliveryEntry;
                      const validDeliveries = deliveries.filter(
                        (delivery) => delivery !== ""
                      );

                      if (validDeliveries?.length > 0) {
                        const tooltipContent = (
                          <div>
                            <strong>Deliveries:</strong>
                            <ul
                              style={{
                                margin: "5px 0 0 0",
                                padding: "0 0 0 15px",
                              }}
                            >
                              {validDeliveries?.map((delivery, index) => (
                                <li key={index}>{delivery}</li>
                              ))}
                            </ul>
                          </div>
                        );

                        const displayCount = Math.min(
                          validDeliveries.length,
                          4
                        );
                        const displayDeliveries = validDeliveries.slice(
                          0,
                          displayCount
                        );

                        return (
                          <Tooltip title={tooltipContent} placement="top">
                            <div className="d-flex flex-column align-items-center">
                              {displayDeliveries.map((delivery, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mb-1"
                                  style={{
                                    width: "100%",
                                    maxWidth: "95%",
                                  }}
                                >
                                  <div
                                    className="bg-primary rounded-circle mr-1"
                                    style={{
                                      width: "6px",
                                      height: "6px",
                                      minWidth: "6px",
                                      marginRight: "4px",
                                    }}
                                  />
                                  <div
                                    className="text-truncate small"
                                    style={{
                                      fontSize: "10px",
                                      lineHeight: "1.1",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {delivery}
                                  </div>
                                </div>
                              ))}
                              {validDeliveries.length > 4 && (
                                <span className="text-primary small">
                                  +{validDeliveries.length - 4} more
                                </span>
                              )}
                            </div>
                          </Tooltip>
                        );
                      }
                    }
                    return null;
                  }}
                />
              </div>
            ) : (
              <div className="p-3">
                <div
                  className="overflow-auto"
                  style={{
                    height: "307px",
                    overflowY: "scroll",
                  }}
                >
                  {validTodaysDeliveries?.length > 0 ? (
                    <ul className="list-group">
                      {validTodaysDeliveries?.map((delivery, index) => (
                        <li key={index} className="list-group-item">
                          {delivery}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-center text-muted">
                      No deliveries scheduled for today.
                    </p>
                  )}
                </div>
              </div>
            )}
            <div className=" d-flex justify-content-center pb-1">
              <Button
                // type="primary"
                // size="small"
                onClick={() => setIsCalendarView(!isCalendarView)}
              >
                {isCalendarView
                  ? "View Today's Deliveries"
                  : "View All Deliveries"}
              </Button>
            </div>
          </>
        )}
      </div>
    ),
  };

  return (
    <>
      <Container className="mt-3">
        {isLoading && (
          <>
            <div className="row d-flex justify-content-center">
              <LoadingComponent />
            </div>
          </>
        )}

        {!isLoading && accessLevel === 0 && (
          <div className="row">
            <div className="text-center">
              <Text>
                You do not have the necessary permissions to access this page.
              </Text>
            </div>
          </div>
        )}
        {!isLoading && accessLevel > 0 && (
          <>
            <Container className="mb-4">
              <ContentContainer className=" py-2 px-4 mb-2">
                <div className=" d-flex flex-wrap gap-3 p-3">
                  {/* <div className="d-flex flex-column mt-2  p-3">
                    <ReactECharts option={gaugeChart} />
                  </div>
                   */}
                  <div className=" d-flex gap-3 align-items-center w-100  justify-content-between">
                    <div className=" d-flex flex-column">
                      <div className=" d-flex flex-column">
                        <Text className=" fs-3 fw-bold opacity-75">
                          Hello {firstName},
                        </Text>
                        <Text className=" fs-2 fw-bold opacity-75">
                          Here's your project summary:
                        </Text>
                      </div>
                    </div>
                    <Tooltip title="Customize Dashboard">
                      <Button
                        className="d-flex align-items-center justify-content-center"
                        type="text"
                        onClick={() => {
                          setIsShowCustomizeDashboard(true);
                        }}
                      >
                        <SettingFilled className=" opacity-75 fs-5" />
                      </Button>
                    </Tooltip>
                  </div>
                  {/* <hr className="w-100" /> */}
                  {dashboardCusomizationData?.usersActivityAnalysisVisualReport
                    ?.visibility === "1" ? (
                    <div className=" d-flex flex-wrap gap-1">
                      <div className="d-flex flex-column mt-2 border border-1 rounded-4 py-3 px-4">
                        <Text className="opacity-75 fs-5">Ball In Court</Text>
                        <div className="d-flex justify-content-between mt-auto align-items-end">
                          <Text className="fs-1 opacity-75">
                            {usersActivityAnalysisVisualReportData?.entriesAssignedInfo
                              ? Object.keys(
                                  usersActivityAnalysisVisualReportData.entriesAssignedInfo
                                ).length
                              : 0}
                          </Text>
                          <Tooltip
                            title={disabledBallInCourt ? "" : "Show Submittals"}
                          >
                            <RightCircleOutlined
                              className={`fs-4 py-2 ${
                                !disabledBallInCourt
                                  ? "opacity-75 pointer"
                                  : "opacity-50"
                              }`}
                              style={
                                disabledBallInCourt
                                  ? { cursor: "not-allowed" }
                                  : {}
                              }
                              onClick={() => {
                                if (!disabledBallInCourt) {
                                  setIsModalOpen(true);
                                  setModalType("ballInCourt");
                                }
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>

                      <div className="d-flex flex-column mt-2 border border-1 rounded-4 p-3">
                        <Text className="opacity-75 fs-5">
                          Items Pending <br /> Your Approval
                        </Text>
                        <div className="d-flex justify-content-between mt-auto align-items-end">
                          <Text className="fs-1 opacity-75">
                            {usersActivityAnalysisVisualReportData?.pendingApprovalsInfo
                              ? usersActivityAnalysisVisualReportData
                                  .pendingApprovalsInfo.length
                              : 0}
                          </Text>
                          <Tooltip
                            title={
                              disabledItemsPending ? "" : "Show Submittals"
                            }
                          >
                            <RightCircleOutlined
                              className={`fs-4 py-2 ${
                                !disabledItemsPending
                                  ? "opacity-75 pointer"
                                  : "opacity-50"
                              }`}
                              style={
                                disabledItemsPending
                                  ? { cursor: "not-allowed" }
                                  : {}
                              }
                              onClick={() => {
                                if (!disabledItemsPending) {
                                  setIsModalOpen(true);
                                  setModalType("itemsPending");
                                }
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>

                      <div className="d-flex flex-column mt-2 border border-1 rounded-4 py-3 px-4">
                        <Text className="opacity-75 fs-5">
                          Successfully <br /> Procured
                        </Text>
                        <div className="d-flex justify-content-between mt-auto align-items-end">
                          <Text className="fs-1 opacity-75">
                            {usersActivityAnalysisVisualReportData?.completedItemsInYourCourt ||
                              0}
                          </Text>
                        </div>
                      </div>

                      <div className="">
                        <div className="d-flex flex-column mt-2 border border-1 rounded-4 p-3">
                          <Text
                            className=" opacity-75"
                            style={{ fontSize: "0.8rem" }}
                          >
                            Total Active Days
                          </Text>
                          {isUserActivityLoading ? (
                            <>
                              <Skeleton.Button
                                active={isUserActivityLoading}
                                className=" mb-1"
                                style={{ width: 50 }}
                                size={"small"}
                              />
                            </>
                          ) : (
                            <>
                              <Text className=" fs-6">
                                {
                                  usersActivityAnalysisVisualReportData?.totalActiveDays
                                }
                              </Text>
                            </>
                          )}
                        </div>
                        <div className="d-flex flex-column mt-2 border border-1 rounded-4 p-3">
                          <Text
                            className=" opacity-75"
                            style={{ fontSize: "0.8rem" }}
                          >
                            Total Contributions
                          </Text>
                          {isUserActivityLoading ? (
                            <>
                              <Skeleton.Button
                                active={isUserActivityLoading}
                                className=" mb-1"
                                style={{ width: 50 }}
                                size={"small"}
                              />
                            </>
                          ) : (
                            <Text className=" fs-6">
                              {
                                usersActivityAnalysisVisualReportData?.totalContributions
                              }
                            </Text>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="d-flex flex-column mt-2 border border-1 rounded-4 p-3">
                          <Text
                            className=" opacity-75"
                            style={{ fontSize: "0.8rem" }}
                          >
                            Most Active Day
                          </Text>
                          {isUserActivityLoading ? (
                            <>
                              <Skeleton.Button
                                active={isUserActivityLoading}
                                className=" mb-1"
                                style={{ width: 50 }}
                                size={"small"}
                              />
                            </>
                          ) : (
                            <Text className=" fs-6">
                              {
                                usersActivityAnalysisVisualReportData?.mostActiveDayDate
                              }
                            </Text>
                          )}
                        </div>

                        <div className="d-flex flex-column mt-2 border border-1 rounded-4 p-3">
                          <Text
                            className=" opacity-75"
                            style={{ fontSize: "0.8rem" }}
                          >
                            Most Active Day Contributions
                          </Text>
                          {isUserActivityLoading ? (
                            <>
                              <Skeleton.Button
                                active={isUserActivityLoading}
                                className=" mb-1"
                                style={{ width: 50 }}
                                size={"small"}
                              />
                            </>
                          ) : (
                            <Text className=" fs-6">
                              {
                                usersActivityAnalysisVisualReportData?.mostActiveDayContributions
                              }
                            </Text>
                          )}
                        </div>
                      </div>

                      <div className="d-flex flex-column mt-2 border border-1 rounded-4 p-3">
                        <Text
                          className=" opacity-75"
                          style={{ fontSize: "0.8rem" }}
                        >
                          First Procurement Log Activity
                        </Text>

                        {isUserActivityLoading ? (
                          <>
                            <Skeleton.Input
                              active={isUserActivityLoading}
                              className=" mb-1"
                            />
                          </>
                        ) : (
                          <Text className=" fs-6">
                            {
                              usersActivityAnalysisVisualReportData?.firstContributionAction
                            }
                          </Text>
                        )}
                        <Text
                          className=" opacity-75"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {
                            usersActivityAnalysisVisualReportData?.firstContributionDate
                          }
                        </Text>
                      </div>

                      <div className="d-flex flex-column mt-2 border border-1 rounded-4 p-3">
                        <Text
                          className=" opacity-75"
                          style={{ fontSize: "0.8rem" }}
                        >
                          Last Procurement Log Activity
                        </Text>
                        {isUserActivityLoading ? (
                          <>
                            <Skeleton.Input
                              active={isUserActivityLoading}
                              className=" mb-1"
                            />
                          </>
                        ) : (
                          <Text className=" fs-6">
                            {
                              usersActivityAnalysisVisualReportData?.lastContributionAction
                            }
                          </Text>
                        )}
                        <Text
                          className=" opacity-75"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {
                            usersActivityAnalysisVisualReportData?.lastContributionDate
                          }
                        </Text>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </ContentContainer>

              <div className=" d-flex gap-2 w-100">
                <ContentContainer
                  className="p-3 w-100"
                  style={{ width: "100%" }}
                >
                  {!showGetFromSubmittals ? (
                    <div className="d-flex gap-1 flex-wrap justify-content-center">
                      {visibleComponents.map((component) => (
                        <React.Fragment key={component.key}>
                          {componentMap[component.key]}
                        </React.Fragment>
                      ))}
                      {visibleComponents.length < 1 && (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          styles={{ image: { height: 80 } }}
                          description={
                            <Typography.Text>
                              Start customizing your dashboard by adding widgets
                              that suit your needs.
                            </Typography.Text>
                          }
                        >
                          <Button
                            type="primary"
                            onClick={() => {
                              setIsShowCustomizeDashboard(true);
                            }}
                          >
                            Set Up Widgets
                          </Button>
                        </Empty>
                      )}
                    </div>
                  ) : (
                    <>
                      <Empty
                        description={
                          <Typography.Text>
                            No procurement log found
                          </Typography.Text>
                        }
                      >
                        <Button type="primary" onClick={GetFromSubmittals}>
                          Get From Submittals
                        </Button>
                      </Empty>
                    </>
                  )}
                </ContentContainer>
              </div>
            </Container>
          </>
        )}
      </Container>

      <Modal
        // title="Submittal Titles"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        {modalType === "itemsPending" ? (
          <>
            <h6>Submittal Titles</h6>

            <ul>
              {usersActivityAnalysisVisualReportData?.pendingApprovalsInfo ? (
                usersActivityAnalysisVisualReportData.pendingApprovalsInfo.map(
                  (item, index) => <li key={index}>{item?.specTitle}</li>
                )
              ) : (
                <li>No data available</li>
              )}
            </ul>
          </>
        ) : modalType === "ballInCourt" ? (
          <>
            <h6>Submittal Titles</h6>

            <ul>
              {usersActivityAnalysisVisualReportData?.entriesAssignedInfo ? (
                Object.values(
                  usersActivityAnalysisVisualReportData.entriesAssignedInfo
                ).map((value, index) => <li key={index}>{value}</li>)
              ) : (
                <li>No data available</li>
              )}
            </ul>
          </>
        ) : modalType === "urgentAction" ? (
          <>
            <h6>Submittal Titles</h6>

            <ul>
              {selectedUrgentAction &&
              urgentActionReportData[selectedUrgentAction]?.titles.length >
                0 ? (
                urgentActionReportData[selectedUrgentAction].titles.map(
                  (title, index) => <li key={index}>{title}</li>
                )
              ) : (
                <li>No data available</li>
              )}
            </ul>
          </>
        ) : modalType === "longLeadTime" ? (
          <>
            <h6>Submittal Titles</h6>

            <ul>
              {selectedLongLeadTimeCategory &&
              longLeadTimeStatusReportData[selectedLongLeadTimeCategory]?.titles
                ?.length > 0 ? (
                longLeadTimeStatusReportData[
                  selectedLongLeadTimeCategory
                ].titles.map((title, index) => <li key={index}>{title}</li>)
              ) : (
                <li>No data available</li>
              )}
            </ul>
          </>
        ) : (
          <></>
        )}
      </Modal>

      <Modal
        open={isContractorChartModalOpen}
        onOk={handleContractorChartModalClose}
        onCancel={handleContractorChartModalClose}
        width={"90vw"}
        centered
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={handleContractorChartModalClose}
          >
            Ok
          </Button>,
        ]}
      >
        {activeChartType === "Contractor" ? (
          <h4 className=" text-center my-2 fw-bold">Items Per Contractor</h4>
        ) : (
          <h4 className=" text-center my-2 fw-bold">Items Per Division</h4>
        )}
        <Tabs
          defaultActiveKey="1"
          size="large"
          items={
            activeChartType === "Contractor"
              ? itemsPerContractorTabs
              : itemsPerDivisionTabs
          }
        />
      </Modal>

      <Modal
        open={isShowCustomizeDashboard}
        onCancel={() => {
          setIsShowCustomizeDashboard(false);
        }}
        width={"90vw"}
        centered
        footer={null}
      >
        <DashboardCustomization
          setIsShowCustomizeDashboard={setIsShowCustomizeDashboard}
          ApplyDashboardCustomizationChanges={
            ApplyDashboardCustomizationChanges
          }
        />
      </Modal>
    </>
  );
}
